import React, { useState, useEffect, useRef } from "react";
import NET from "vanta/dist/vanta.net.min";
import * as THREE from "three";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import arrows from "../../utils/lf30_editor_8n6vqver_arrows.json";
import Lottie from "lottie-react";
import { FadeIn } from "react-slide-fade-in";
import { useOutletContext } from "react-router-dom";

const StyledDiv = styled.div`
	overflow-x: hidden;
	.vanta-bg {
		background: linear-gradient(
			180deg,
			rgba(79, 80, 82, 1) 25%,
			rgba(79, 80, 82, 0.5) 75%,
			rgba(255, 255, 255, 1) 100%
		);
	}

	.banner-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 90vh;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0) 50%,
			rgba(255, 255, 255, 0.75) 100%
		);

		.banner-txt {
			width: 100%;
			text-align: center;
			padding: 50px 0;

			.heading {
				font-size: 8vw;
				display: inline-flex;
			}

			p {
				font: normal normal normal 2.5vw/4vw Nexa-light;
				padding: 50px 0;
				width: 100%;
				display: inline-flex;
				text-align: center;
				margin-right: auto;
				margin-left: auto;
				justify-content: center;
			}
		}

		.banner-img {
			width: 25vw;
			height: 25vw;
			position: relative;

			img {
				width: 80%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.banner-content {
			.banner-txt {
				.heading {
					font-size: 13vw;
				}

				p {
					font-size: 6.5vw;
					width: 90%;
					padding: 50px 0;
				}
			}
		}
	}
`;

const HomeBanner = () => {
	const [vantaEffect, setVantaEffect] = useState(0);
	const vantaRef = useRef(null);
	const { setIsSubModalOpen } = useOutletContext();

	useEffect(() => {
		try {
			if (!vantaEffect) {
				setVantaEffect(
					NET({
						el: vantaRef.current,
						color: 0xe2964a,
						backgroundAlpha: 0,
						THREE: THREE,
						mouseControls: true,
						touchControls: true,
						gyroControls: false,
						scale: 1.0,
						scaleMobile: 1.0,
						points: 10.0,
						maxDistance:
							window.screen.width >= 500
								? 25.0
								: 15.0,
						spacing:
							window.screen.width >= 500
								? 25.0
								: 20.0,
					})
				);
			}
		} catch (error) {
			console.error("Error initializing Vanta effect:", error);
		}
	
		return () => {
			try {
				if (vantaEffect) vantaEffect.destroy();
			} catch (error) {
				console.error("Error destroying Vanta effect:", error);
			}
		};
	}, [vantaEffect]);
	
	return (
		<StyledDiv>
			<div ref={vantaRef} className="vanta-bg">
				<div className="banner-content">
					<div className="banner-txt elite">
						<div className="heading">
							<FadeIn
								from="right"
								delayInMilliseconds={
									0
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								L
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									200
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								i
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									300
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								v
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									400
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								e{" "}
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									600
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								{" "}
								&nbsp;S
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									800
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								m
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									1000
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								a
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									1200
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								r
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									1400
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								t
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									1600
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								e
							</FadeIn>
							<FadeIn
								from="right"
								delayInMilliseconds={
									1800
								}
								positionOffset={
									50
								}
								triggerOffset={
									200
								}>
								r
							</FadeIn>
						</div>

						<p
							className="topBannerPara"
							data-aos="fade-up"
							data-aos-delay={1000}
							data-aos-duration={
								1200
							}>
							Enhance your lifestyle.
						</p>

						<a href="#home">
							<button
								data-aos="fade-up"
								data-aos-delay={
									1000
								}
								data-aos-duration={
									1200
								}
								onClick={() => {
									setIsSubModalOpen(
										true
									);
								}}
								className="section-btn">
								Get Started
							</button>
						</a>
					</div>

					<div className="downArrows">
						<a href="#call-to-action">
							<Lottie
								animationData={
									arrows
								}
								background="transparent"
								speed="1"
								style={{
									width: "90px",
									height: "92px",
									margin: "auto",
								}}
								loop
								autoplay></Lottie>
						</a>
					</div>
				</div>
			</div>
		</StyledDiv>
	);
};

export default HomeBanner;
