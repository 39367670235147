import React from "react";
import styled from "styled-components";
import logo from "../../images/copyrightIGlogo.png";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
import { breakpoints } from "../../utils/breakpoints";

const StyledHeader = styled.div`
	position: relative;
	width: 100%;
	height: 10vh;
	background-color: #ffffff;
	box-shadow: 0px 3px 16px #3d3d3d14;

	.content-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 98%;

		.logo {
			text-align: left;
			cursor: pointer;

			img {
				height: 10vh;
			}
		}
	}

	@media screen and ${breakpoints.device.laptopL} {
		padding: 2px 0;
	}

	@media screen and ${breakpoints.device.tablet} {
		padding: 2px 0;
		.content-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 99%;

			.logo {
				text-align: left;
				cursor: pointer;

				img {
					height: 10vh;
				}
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		height: 7.5vh;
		.content-container {
			width: 90%;

			.logo {
				margin-left: -15px;
				img {
					height: 7vh;
				}
			}
		}
	}
`;

const Header = ({ setIsSubModalOpen }) => {
	return (
		<StyledHeader>
			<div className="content-container">
				<div className="logo">
					<Link to="/">
						<img src={logo} alt="logo" />
					</Link>
				</div>
				<Navigation
					setIsSubModalOpen={setIsSubModalOpen}
				/>
			</div>
		</StyledHeader>
	);
};

export default Header;
