import React from "react";
import styled from "styled-components";
import bg from "../../images/compressedContact.jpg";
import email_icon from "../../images/contact-email-icon.svg";
import phone_icon from "../../images/contact-phone-icon.svg";
import location_icon from "../../images/contact-location-icon.svg";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
overflow-x: hidden;
	background: #001313 0% 0% no-repeat padding-box;
	background-size: cover;
	background-image: linear-gradient(
			rgba(0, 19, 19, 0.8),
			rgba(0, 19, 19, 0.8)
		),
		url("${bg}");
	color: #ffffff;

	.content-container {
		padding: 100px 0;

		.contact-us-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 100px;
			width: 75%;
			margin: auto;
			padding: 100px 0;
			text-align: left;
			font: normal normal normal 24px/40px Nexa-light;

			.info {
				display: flex;
				align-items: center;

				.txt {
					padding-left: 25px;

					.title {
						color: #44b2dd;
					}
				}
			}
		}
	}

	@media screen and ${breakpoints.device.laptopL} {
		.content-container {
			.contact-us-container {
				font: normal normal normal 20px/34px Nexa-light;
			}
		}
		
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 60px 0;
			.contact-us-container {
				padding: 30px 0;
				grid-template-columns: 1fr;
				width: 85%;
				

				.info {
					flex-direction: column;

					.txt {
						padding: 0;
						text-align: center;
					}
				}
			}
		}
	}
`;

const ContactUs = () => {
	return (
		<StyledDiv>
			<div className="content-container">
				<div className="heading">Get In Touch</div>
				<div className="contact-us-container">
					<div className="info">
						<div className="icon">
							<img
								src={email_icon}
								alt=""
							/>
						</div>
						<div className="txt">
							<span className="title">
								Email
							</span>
							<br />
							support@igeniusglobal.com
						</div>
					</div>
					<div className="info">
						<div className="icon">
							<img
								src={phone_icon}
								alt=""
							/>
						</div>
						<div className="txt">
							<span className="title">
								Phone
							</span>
							<br />
							1-801-939-3580
						</div>
					</div>
					<div className="info">
						<div className="icon">
							<img
								src={
									location_icon
								}
								alt=""
							/>
						</div>
						<div className="txt">
							<span className="title">
								Headquarters
							</span>
							<br />
							459 North 300 West
							<br />
							Suite 15B
							<br />
							Kaysville, UT 84037
						</div>
					</div>
					<div className="info">
						<div className="icon">
							<img
								src={
									location_icon
								}
								alt=""
							/>
						</div>
						<div className="txt">
							<span className="title">
								Europe
								Headquarters
							</span>
							<br />
							iGenuis Global LTD Room SF2. 11-14 Newry Street,
							<br />
							Warrenpoint, Newry, Co.
							<br />
							Northern Ireland, BT34 3JZ
						</div>
					</div>
				</div>
			</div>
		</StyledDiv>
	);
};

export default ContactUs;
