import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
	.modal {
		position: fixed; /* Stay in place */
		z-index: 1; /* Sit on top */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		background-color: rgb(0, 0, 0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

		.modal-content-new {
			margin: auto;
			padding: 150px 20px;
			width: 40%;

			.video-container {
				background-color: #fefefe;
				margin: auto;
				padding: 20px;
				border: 1px solid #888;
				width: auto;
				box-shadow: 0px 0px 80px 20px black;

				#vimeo-small-iframe {
					display: none;
				}
			}

			/* The Close Button */
			.closeNew {
				color: #aaaaaa;
				font-size: 35px;
				font-weight: bold;

				&:hover,
				&:focus {
					color: #000;
					text-decoration: none;
					cursor: pointer;
				}
			}
		}
	}

	@media only screen and (max-width: 1000px) {
		.modal {
			.modal-content-new {
				position: relative;
				margin: auto;
				padding: 5px;
				width: 90% !important;

				.video-container {
					background-color: #fefefe;
					margin: auto;
					padding: 10px;
					border: 1px solid #888;
					width: 90%;
					box-shadow: 0px 0px 80px 20px black;

					#vimeo-small-iframe {
						display: inline-block !important;
					}

					#vimeo-iframe {
						display: none;
					}
				}
			}
		}
	}
`;

const Modal = ({ vidType, vidCode, setIsModalOpen, isInsta }) => {
	// Close the modal if the user clicks outside of it
	window.onclick = function (e) {
		if (
			e.target.firstChild.className === "modal-content-new" ||
			e.target.firstChild.className === "closeNew" ||
			e.target.className === "closeNew" ||
			e.target.children[1].className === "video-container"
		) {
			setIsModalOpen(false);
		}
	};

	return (
		<StyledDiv>
			<div className="modal">
				<div className="modal-content-new">
					<div
						style={{
							textAlign: "right",
						}}>
						<span
							className="closeNew"
							onClick={() =>
								setIsModalOpen(
									false
								)
							}>
							&times;
						</span>
					</div>
					<div className="video-container">
						<iframe
							title="vimeo"
							id="vimeo-iframe"
							src={
								(vidType ===
								"youtube"
									? "https://youtube.com/embed/"
									: "https://player.vimeo.com/video/") +
								vidCode
							}
							width="100%"
							height="360"
							webkitallowfullscreen=""
							mozallowfullscreen=""
							allowFullScreen></iframe>
						<iframe
							title="vimeo"
							id="vimeo-small-iframe"
							src={
								(vidType ===
								"youtube"
									? "https://youtube.com/embed/"
									: "https://player.vimeo.com/video/") +
								vidCode
							}
							width="100%"
							height="240"
							webkitallowfullscreen=""
							mozallowfullscreen=""
							allowFullScreen></iframe>
					</div>
				</div>
			</div>
		</StyledDiv>
	);
};

export default Modal;
