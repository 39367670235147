import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EventCard from "./EventCard";
import { breakpoints } from "../../utils/breakpoints";
import Modal from "../../Sections/Modal/Modal";
import TopBannerVideo from "../../Sections/TopBanner/TopBannerVideo";
import { motion } from "framer-motion";

// banner video
import bannerVid from "../../videos/SecondTestCompressEvents.mp4";

// event thumbnails
import egypt from "../../images/egypt-eventspage.png";
import islandCrete from "../../images/island-crete-eventspage.png";
import jamaica from "../../images/jamaica-eventspage.png";
import northAmerican from "../../images/north-american-eventspage.png";
import puntaCana from "../../images/punta-cana-eventspage.png";
import summerSymp from "../../images/summer-symp-eventspage.png";
import womensGala from "../../images/womens-gala-eventspage.png";
import northSouth from "../../images/north-x-south-eventspage.png";
import summit2022 from "../../images/NewSite2022Summit.png";
import RiveraM from "../../images/RiveraMayaBanner.png";
import Ignite from "../../images/ignitePlayButton.png";
import na2023 from "../../images/NorthAmerican2023EventsPage.png";
import summerSymp23 from "../../images/summerSymp23new.png";
import stJames from "../../webp/stjames-eventpage.webp";
import UniteEvent from "../../images/UniteEventPublic.png";
import gala2023 from "../../images/2023galaEvent.png";
import spainLS from "../../webp/spainLSevent.webp";
import impact from "../../images/impactPast2024.png";
import evolveC from "../../images/evolvePublic.png";
import ibiza from "../../images/ibiza_event.png";

const StyledDiv = styled.div`
	overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;

	.videoEvent {
		width: 100%;
	}

	.content-container {
		padding: 100px 0;

		.heading {
			text-align: left;
		}

		.events-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 100px;
			padding: 50px 0;
			margin-bottom: 50px;
		}

		.modal-container {
			display: none;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 50px 0;
			width: 90%;

			.heading {
				font-size: 8vw;
			}

			.events-container {
				grid-template-columns: 1fr;
				grid-gap: 40px;
				margin-bottom: 20px;
			}
		}
	}
`;

const Events = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [vidCode, setVidCode] = useState(null);

	useEffect(() => {
		var modal = document.getElementById("modal");
		var video = document.getElementById("vimeo-iframe");

		if (isModalOpen) {
			modal.style.display = "block";
		} else {
			modal.style.display = "none";
			var temp = video.getAttribute("src")
			video.setAttribute("src", temp);
		}
	}, [isModalOpen]);

	return (
		<StyledDiv>
			<TopBannerVideo
				video={bannerVid}
				txt={
					<>
						<b>See the world</b> with us!
						iGenius hosts and attends
						numerous events throughout the
						year all around the world.
					</>
				}
			/>
			<div className="content-container">
				<div className="upcoming-events-section">
					<motion.div
						initial={{ opacity: 0, x: -200, y: -50 }}
						whileInView={{
							opacity: 1,
							x: 0, y: 0
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="heading">
							Upcoming Events
						</div>
					</motion.div>
					<div className="events-container">
						{upcomingEvents.map(
							(
								upcomingEvent,
								idx
							) => (
								<EventCard
									key={
										idx
									}
									data={
										upcomingEvent
									}
									buyButton={
										upcomingEvent.buyButton
									}
									learnMoreButton={
										upcomingEvent.learnMoreButton
									}
									isModalOpen={
										isModalOpen
									}
									setIsModalOpen={
										setIsModalOpen
									}
									setVidCode={
										setVidCode
									}
								/>
							)
						)}
					</div>
				</div>
				<div className="past-events-section">
					<motion.div
						initial={{ opacity: 0, x: -200, y: -50 }}
						whileInView={{
							opacity: 1,
							x: 0, y: 0
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1,
						}}>
						<div className="heading">
							Past Events
						</div>
					</motion.div>
					<div className="events-container">
						{pastEvents.map(
							(pastEvent, idx) => (
								<EventCard
									key={
										idx
									}
									data={
										pastEvent
									}
									isModalOpen={
										isModalOpen
									}
									setIsModalOpen={
										setIsModalOpen
									}
									setVidCode={
										setVidCode
									}
								/>
							)
						)}
					</div>
				</div>
				<div id="modal" className="modal-container">
					<Modal
						vidType="youtube"
						vidCode={vidCode}
						setIsModalOpen={setIsModalOpen}
					/>
				</div>
			</div>
		</StyledDiv>
	);
};

const upcomingEvents = [
	{
		title: "Ibiza Spain Incentive",
		desc: "65 of our members will qualify for a 3-night stay at the all-inclusive TRS Ibiza Hotel in Ibiza, Spain on May 26th-29th, 2025! We can't wait to celebrate our top performers in Spain! During the event we'll host exclusive trainings, mastermind sessions, and an unforgettable awards ceremony! The qualification period is from June 14th - October 10th.",
		image: ibiza,
		location: "Prague, Czech Republic",
		learnMoreButton: "https://igeniusglobal.io/images/Ibiza_Spain_Incentive_English.pdf",
		vidCode: "Wrc8rfQlYAw "
	},
	{
		title: "EVOLVE - 2025 European Convention",
		desc: "Join the iGenius Evolution! EVOLVE, the 2025 European convention, will be held in Prague, Czech Republic, on July 17th - 20th, 2025 at the PVA EXPO PRAHA",
		image: evolveC,
		location: "Ibiza, Spain",
		buyButton: "https://www.events.igeniustickets.com",
		vidCode: "IV6bRuxPR6E "
	},
];

const pastEvents = [
	{
		title: "iMPACT - 2024 U.S Event",
		desc: "At iMPACT we came together to learn, celebrate, and grow in a variety of areas. Top field leaders, iGenius corporate, and iGenius market educators helped attendees feel inspired to take control of their financial future, shape their life, and helped everyone leave with a sense of empowerment!",
		image: impact,
		location: "Orlando, Florida",
		vidCode: "QLiWk9Jk7YQ"
	},
	{
		title: "UNITE - 2024 European Convention",
		desc: "UNITE will be in Düsseldorf, Germany on June 13th - 16th, 2024 at the Mitsubishi Electric HALLE. In 2023 the fire was ignited, now it's time to unite. Join forces with the corporate team, top field leaders, and guest speakers as we come together to take iGenius to the next level. \nThis event will be bigger and better than ever, so join us in Germany as we solidify the future of iGenius!",
		image: UniteEvent,
		location: "Düsseldorf, Germany", 
		vidCode: "6pGwvoOX7FE",
	},
	{
		title: "St. James Incentive",
		desc: "We had a fantastic iGenius incentive trip at the Secrets St. James resort in Montego Bay, Jamaica! We celebrated some of our top producer's success, had exclusive training, went on an amazing waterfall excursion, and had a special celebratory recognition dinner party.",
		image: stJames,
		location: "Montego Bay, Jamaica",
		vidCode: "eroq0Br5Hk8",
	},
	{
		title: "2023 Leadership Summit",
		desc: "The 2023 Leadership Summit was held in Madrid, Spain at the beautiful Melia Princessa Hotel. Attendees enjoyed an awards dinner, team building activities, and got the opportunity to mastermind with leaders from all around the world and the corporate team to help shape the future of iGenius.",
		image: spainLS,
		location: "Madrid, Spain",
		vidCode: "-gPIXCvcqD4",
	},
	{
		title: "iGenius Women's Gala 2023",
		desc: "Top iGenius female leaders hosted the unforgettable Wome's Gala in Mainz, Germany. Hundreds of women gathered together to learn, grow, celebrate, and empower each other.",
		image: gala2023,
		location: "Mainz, Germany",
		vidCode: "0XMK21KG0Ec",
	},
	{
		title: "Summer Symposium 2023",
		desc: "iGenius top field leaders put together an amazing, value packed event. Attendees received top notch training, team building, and experienced an unforgettable yacht party!",
		image: summerSymp23,
		location: "Hollywood, FL",
		vidCode: "kUy7uay9_FI",
	},
	{
		title: "IGNITE 2023",
		desc: "We had an amazing IGNITE European Convention at the RTM Stage in Rotterdam, Netherlands! The event was filled with exclusive training, insights, and inspiration to IGNITE 2023!",
		image: Ignite,
		location: "Rotterdam, Netherlands",
		vidCode: "xj2xiv-8TYM",
	},
	{
		title: "Riviera Maya Incentive",
		desc: "We had an awesome iGenius incentive trip in Riviera Maya, Mexico. We celebrated some of our top producer's success, had special trainings, enjoyed the beautiful amenities and beaches, and went on an unforgettable excursion swimming with turtles.",
		image: RiveraM,
		location: "Riviera Maya, Mexico",
		vidCode: "rlCX59sLukA",
		buyButton: "",
	},
	{
		title: "2023 North American Kickoff",
		desc: "iGenius members from all over North America attended the 2023 North American Kickoff in Atlanta, Georgia. The event was hosted by top iGenius field leaders. Attendees benefited from advanced service, product, and marketing training. The event was closed with an unforgettable awards ceremony celebrating the success and growth of the company and its distributors.",
		image: na2023,
		location: "Atlanta, Georgia",
		vidCode: "xdRtbKq7HQM",
	},
	{
		title: "2022 Leadership Summit",
		desc: "The 2022 Leadership Summit was held in Las Vegas, Nevada. Qualifiers got to benefit from advanced training, mastermind sessions, special awards, and an awesome off-site team building activity at Top Golf!",
		image: summit2022,
		location: "Las Vegas, Nevada",
		vidCode: "eelhjgWfDIE",
	},
	{
		title: "iGenius Women's Gala 2022",
		desc: "Top iGenius female leaders hosted an unforgettable iGenius Women's Gala in Stuttgart, Germany. Hundreds of women gathered together and were empowered by special guest speakers and an open panel event focusing on rising stars, leaders, and the power of iGenius.",
		image: womensGala,
		location: "Stuttgart, Germany",
		vidCode: "W-kEAt0XXEU",
	},
	{
		title: "Punta Cana Incentive",
		desc: "We had an awesome iGenius incentive trip in Punta Cana, Dominican Republic! We celebrated some of our top producer's success, had top notch training, and enjoyed the sun, sand, and relaxed on the beautiful beaches!",
		image: puntaCana,
		location: "Punta Cana, DOM REP",
		vidCode: "E2rc0y4qp0c",
	},
	{
		title: "Summer Symposium 2022",
		desc: "iGenius top field leaders hosted the 2022 Summer Symposium in Hollywood, Florida. There was an event, advanced training, team building, and a yacht party dinner cruise!",
		image: summerSymp,
		location: "Hollywood, FL",
		vidCode: "Hk1odkdD3Xo",
	},
	{
		title: "North American Kickoff",
		desc: "The North American Kickoff event in Atlanta, Georgia was hosted by iGenius top field leaders. Attendees benefited from advanced training on iGenius products, mindset, marketing, and business building.",
		image: northAmerican,
		location: "Atlanta, GA",
		vidCode: "x6WcQ-QSf-k",
	},
	{
		title: "Island of Crete Incentive",
		desc: "iGenius celebrated their top producer's success in Crete, Greece! Attendees got to mix and mingle with the iGenius corporate team, receive top notch training, and enjoy one of the most beautiful islands in the world!",
		image: islandCrete,
		location: "Crete,GR",
		vidCode: "zOcWAOsAZyQ",
	},
	{
		title: "HODL Summit in Egypt",
		desc: "iGenius top field leaders hosted an unforgettable event in Cairo, Egypt!",
		image: egypt,
		location: "Cairo, EG",
		vidCode: "Zhwh15otWKk",
	},
	{
		title: "Jamaica Incentive Trip 2021",
		desc: "iGenius celebrated their top producers in Montego Bay, Jamaica! There was food, drinks, golf, training, recognition, good company, and lots of fun in the sun!",
		image: jamaica,
		location: "Montego Bay, JA",
		vidCode: "oUDcimUCnUQ",
	},
	{
		title: "North X South 3",
		desc: "The North X South 3 event was held in Jensen Beach, Florida. Attendees benefited from a jam packed day of training, education, recognition, and special announcements!",
		image: northSouth,
		location: "Jensen Beach, FL",
		vidCode: "xCxjs0-Mnx8",
	},
];

export default Events;
