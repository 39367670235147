import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import { motion } from "framer-motion";

const StyledDiv = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 16px #e9e9e9;
	border-radius: 4px;
	text-align: left;
	height: 100%;

	.video-section {
		position: relative;
		height: auto;
		background: transparent 0% 0% no-repeat padding-box;
		border-radius: 8px 8px 0px 0px;

		img {
			width: 100%;
		}

		.modal-btn {
			border: none;
			background-color: transparent;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			z-index: 1;
			cursor: pointer;
		}
	}

	.txt-section {
		padding: 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 35%;

		.name {
			font: normal normal bold 1.5vw/2.5vw Nexa-bold;
		}

		.story {
			font: normal normal normal 1.5vw/2.5vw Nexa-light;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.txt-section {
			padding: 20px;
			.name,
			.story {
				font-size: 4vw;
				line-height: 7vw;
			}
		}
	}
`;

const StoryCard = ({ data, idx, isModalOpen, setIsModalOpen, setVidCode }) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: idx % 2 === 0 ? -200 : 200 }}
			whileInView={{
				opacity: 1,
				x: 0,
				y: 0,
			}}
			viewport={{ once: true }}
			transition={{
				type: "tween",
				duration: 1,
			}}>
			<StyledDiv>
				<div className="video-section">
					<button
						className="modal-btn"
						onClick={() => {
							setIsModalOpen(
								!isModalOpen
							);
							setVidCode(data.vidID);
						}}
					/>
					<img src={data.img} alt="" />
				</div>
				<div className="txt-section">
					<div className="story">
						{data.story}
					</div>
					<div className="name">
						- {data.name}, {data.location}
					</div>
				</div>
			</StyledDiv>
		</motion.div>
	);
};

export default StoryCard;
