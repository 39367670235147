import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	position: relative;
	text-align: center;
	width: 15vw;
	height: 15vw;
	color: #ffffff;

	.policy-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;

		.icon {
			width: 25px;
			transform: scale(3);
			margin: auto;
		}

		.txt {
			padding: 25px 0;
			font-size: 20px;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		width: 55vw;
		height: 55vw;
	}
`;

const Policy = ({ id, txt, icon }) => {
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		var comp = document.getElementById(id.toString());
		if (window.screen.width > 500) {
			if (isHovered) {
				comp.classList.add("hovered");
			} else {
				comp.classList.remove("hovered");
			}
		}
	});

	return (
		<StyledDiv
			id={id}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}>
			<div className="policy-content">
				<div className="icon">{icon}</div>
				<div className="txt">{txt}</div>
			</div>
		</StyledDiv>
	);
};

export default Policy;
