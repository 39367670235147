import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import topText1 from "../../images/Text_Background_for_all_pages.png";
import topText2 from "../../images/Text_Background_for_all_pages_2.png";
import topText3 from "../../images/Text_Background_for_all_pages_3.png";

const StyledDiv = styled.div`
	position: relative;

	.topBanner {
		width: 100%;
		height: 100%;
	}

	.topText {
		width: 100%;
		height: 35vh;
		margin-top: -5px;
		position: relative;

		.sub-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			width: 90%;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		min-height: 30vh;

		.topText {
			height: 20vh;
			.sub-text {
				font-size: 4vw;
				padding: 25px 0;
				line-height: 7vw;
			}
		}

		.section-btn {
			font-size: 2.5vw;
		}
	}
`;

const TopBannerVideo = ({ bg, txt, logo, btn, video, isInvestview }) => {
	return (
		<StyledDiv className="banner">
			{isInvestview ? (
				<a
					href="https://investview.com/"
					target="_blank"
					rel="noreferrer">
					<video
						autoPlay
						muted
						className="videoEvent"
						playsInline
						style={{ width: "100%" }}>
						<source
							src={video}
							type="video/mp4"
						/>
					</video>
				</a>
			) : video ? (
				<video
					autoPlay
					muted
					loop
					className="videoEvent"
					playsInline
					style={{ width: "100%" }}>
					<source src={video} type="video/mp4" />
				</video>
			) : (
				<img
					src={bg}
					alt="topBanner"
					className="topBanner"
				/>
			)}
			<div className="topText">
				<p className="sub-text">
					{txt}
					<br />
					{btn}
				</p>
				<img
					src={
						window.screen.width > 1000
							? topText1
							: window.screen.width >
							  500
							? topText2
							: topText3
					}
					alt="topText"
					className="topText"
				/>
			</div>
		</StyledDiv>
	);
};

export default TopBannerVideo;
