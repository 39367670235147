import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// thumbnails
import verena from "../../webp/Larger Testimonial thumbnails-07.webp";
import christian from "../../images/Testimonials-web thumbnails-Christian.png";
import jeremy from "../../images/Testimonials-web thumbnails-Jeremy.png";
import kristel2 from "../../images/Testimonials-web thumbnails-Kristel.png";

const StyledDiv = styled.div`
	overflow-x: hidden;
	padding: 100px 0;
	background: #ffffff no-repeat padding-box;

	.stories {
		padding: 50px 0;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 100px;

		.story {
			position: relative;
			box-shadow: 0px 3px 16px #e9e9e9;
			border-radius: 4px;

			img {
				width: 100%;
			}

			.modal-btn {
				border: none;
				background-color: transparent;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				z-index: 1;
				cursor: pointer;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		padding: 50px 10px;

		.stories {
			padding: 30px 0;
			grid-template-columns: 1fr;
			row-gap: 50px;
		}
	}
`;

const SuccessStoriesSection = ({ isModalOpen, setIsModalOpen, setVidCode }) => {
	return (
		<StyledDiv>
			<div className="content-container">
				<motion.div
					initial={{ opacity: 0, x: 200 }}
					whileInView={{
						opacity: 1,
						x: 0,
					}}
					viewport={{ once: true }}
					transition={{
						type: "tween",
						duration: 1,
					}}>
					<div className="heading">
						Success Stories
					</div>
					<div className="divider"></div>
					<p>
						<b>Impacting lives</b> all
						around the world! Hear what some
						of our members have to say.
					</p>
				</motion.div>
				<div className="stories">
					<motion.div
						initial={{ opacity: 0, x: 100 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1.1,
						}}
						className="story">
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"F8J7NHaZlHE"
								);
							}}
						/>
						<img src={christian} alt="" />
					</motion.div>
					<motion.div
						initial={{ opacity: 0, x: 200 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1.3,
						}}
						className="story">
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"JxJaWVen8bA"
								);
							}}
						/>
						<img src={kristel2} alt="" />
					</motion.div>
					<motion.div
						initial={{ opacity: 0, x: 300 }}
						whileInView={{
							opacity: 1,
							x: 0,
						}}
						viewport={{ once: true }}
						transition={{
							type: "tween",
							duration: 1.5,
						}}
						className="story">
						<img src={jeremy} alt="" />
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"gOmTwaPDnlI"
								);
							}}
						/>
					</motion.div>
				</div>
				<Link to="/successstories">
					<button className="section-btn">
						View Stories
					</button>
				</Link>
			</div>
		</StyledDiv>
	);
};

export default SuccessStoriesSection;
