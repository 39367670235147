import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

// event thumbnails
import egypt from "../../webp/egypt-homepage.webp";
import egyptMobile from "../../webp/EgyptMobile.webp";
import puntaCana from "../../webp/punta-cana-homepage.webp";
import puntaCanaMobile from "../../webp/PuntaCanaBannerMobile.webp";
//import summerSymp from "../../images/summer-symp-homepage.png";
//import summerSympMobile from "../../images/summitBannerMobile.png";
//import womensGala from "../../images/womens-gala-homepage.png";
//import womensGalaMobile from "../../images/WomenGalaMobile.png";
import stjames from "../../webp/stjamesHome.webp";
import stjamesMobile from "../../webp/stjamesHomeMobile.webp";
import jamaica from "../../webp/jamaica-homepage.webp";
import jamaicaMobile from "../../webp/JamaicaBannerMobile.webp";
import ignitePublic from "../../webp/IgnitePublicBanner.webp";
import ignitePublicMobile from "../../webp/igniteMobileBannerHome.webp";
import gala2023home from "../../webp/2023galaHome.webp";
import gala2023mobile from "../../webp/2023galaMobile.webp";

const StyledDiv = styled.div`
	overflow-x: hidden;
	overflow-y: hidden;
	padding: 100px 0;
	background: #ffffff no-repeat padding-box;

	.events {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		grid-gap: 20px;
		padding-top: 50px;
		margin: auto;

		.event {
			position: relative;

			.modal-btn {
				border: none;
				background-color: transparent;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				z-index: 1;
				cursor: pointer;
			}

			.event-title {
				position: absolute;
				bottom: 10%;
				left: 10%;
				font: normal normal bold 1vw/2vw Nexa-bold;
				color: #ffffff;
			}

			img {
				filter: brightness(90%);
				width: 100%;
				height: 100%;
			}
		}

		.island-crete {
			grid-column: 1 / 3;
		}

		.egypt {
			grid-column: 3;
		}

		.womens-gala {
			grid-column: 1;
			grid-row: 2 / 4;
		}

		.punta-cana {
			grid-column: 2;
			grid-row: 2;
		}

		.jamaican {
			grid-column: 3;
			grid-row: 2;
		}

		.summer-symp {
			grid-column: 2 / 4;
			grid-row: 3;
		}

		.animation {
			height: 100%;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		padding: 20px 10px;
		padding-top: 25px;

		.events {
			grid-template-columns: 1fr;
			grid-template-rows: none;
			padding-top: 20px;
			grid-gap: 10px;

			.event .event-title {
				font-size: 5vw;
			}

			.island-crete,
			.egypt,
			.womens-gala,
			.punta-cana,
			.jamaican,
			.summer-symp {
				grid-column: 1;
			}

			.island-crete {
				grid-row: 1;
			}

			.egypt {
				grid-row: 2;
			}

			.womens-gala {
				grid-row: 3;
			}

			.punta-cana {
				grid-row: 4;
			}

			.jamaican {
				grid-row: 5;
			}

			.summer-symp {
				grid-row: 6;
			}
		}
	}
`;

const EventsSection = ({ isModalOpen, setIsModalOpen, setVidCode }) => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		if (window.screen.width <= 500) {
			setIsMobile(true);
		}
	}, [isMobile]);

	return (
		<StyledDiv>
			<div className="content-container">
				<div
					className="heading"
					data-aos="fade-down"
					data-aos-duration={1000}>
					<div className="heading">Events</div>
					<div className="divider"></div>
					<p>
						<b>See the world</b> with us!
						iGenius hosts and attends
						numerous events throughout the
						year all around the world.
					</p>
				</div>
				<div className="events">
					<div
						className="island-crete event"
						data-aos="fade-down-right"
						data-aos-duration={2000}>
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"xj2xiv-8TYM"
								);
							}}
						/>

						<img
							src={
								isMobile
									? ignitePublicMobile
									: ignitePublic
							}
							alt=""
						/>

						<div className="event-title">
							IGNITE European Convention
						</div>
					</div>

					<div
						className="egypt event"
						data-aos="fade-down-left"
						data-aos-duration={2000}>
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"Zhwh15otWKk"
								);
							}}
						/>
						<img
							src={
								isMobile
									? egyptMobile
									: egypt
							}
							alt=""
						/>
						<div className="event-title">
							HODL Summit in Egypt
						</div>
					</div>
					<div
						className="womens-gala event"
						data-aos="fade-up-right"
						data-aos-duration={2000}>
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"0XMK21KG0Ec"
								);
							}}
						/>
						<img
							src={
								isMobile
									? gala2023mobile
									: gala2023home
							}
							alt=""
						/>
						<div className="event-title">
							iGenius Women's Gala
							2023
						</div>
					</div>
					<div
						className="punta-cana event"
						data-aos="fade-up"
						data-aos-duration={2000}>
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"E2rc0y4qp0c"
								);
							}}
						/>
						<img
							src={
								isMobile
									? puntaCanaMobile
									: puntaCana
							}
							alt=""
						/>
						<div className="event-title">
							Punta Cana Incentive
						</div>
					</div>
					<div
						className="jamaica event"
						data-aos="fade-left"
						data-aos-duration={2000}>
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"oUDcimUCnUQ"
								);
							}}
						/>
						<img
							src={
								isMobile
									? jamaicaMobile
									: jamaica
							}
							alt=""
						/>
						<div className="event-title">
							Jamaica Incentive Trip
							2021
						</div>
					</div>
					<div
						className="summer-symp event"
						data-aos="fade-up-left"
						data-aos-duration={2000}>
						<button
							className="modal-btn"
							onClick={() => {
								setIsModalOpen(
									!isModalOpen
								);
								setVidCode(
									"eroq0Br5Hk8"
								);
							}}
						/>
						<img
							src={
								isMobile
									? stjamesMobile
									: stjames
							}
							alt=""
						/>
						<div className="event-title">
							St. James Incentive
						</div>
					</div>
				</div>
			</div>
		</StyledDiv>
	);
};

export default EventsSection;
