import React, { useState, useEffect } from "react";
import "./App.css";
import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Outlet,
	createRoutesFromElements,
	ScrollRestoration,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Cookies from 'universal-cookie';

// sections
import Header from "./Sections/Header/Header";
import Footer from "./Sections/Footer/Footer";
import SubmissionModal from "./Sections/Modal/SubmissionModal";
import "./cookie.css";

// pages
import Home from "./Pages/Home/Home";
import Products from "./Pages/Products/Products";
import Opportunity from "./Pages/Opportunity/Opportunity";
import MemberBenefits from "./Pages/MemberBenefits/MemberBenefits";
import Events from "./Pages/Events/Events";
import SuccessStories from "./Pages/SuccessStories/SuccessStories";
import IGeniusCares from "./Pages/IGeniusCares/IGeniusCares";
import Investview from "./Pages/Investview/Investview";
import Leadership from "./Pages/Leadership/Leadership";
import Policies from "./Pages/Policies/Policies";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Login from "./Pages/Login/Login";
import PurchaseTerms from "./Pages/Policies/PurchaseTerms";
import PrivacyPolicy from "./Pages/Policies/PrivacyPolicy";
import DistributorAgreement from "./Pages/Policies/DistributorAgreement";
import Compliance from "./Pages/Policies/Compliance";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Layout />}>
			<Route index element={<Home />} />
			<Route path="products" element={<Products />} />
			<Route path="opportunity" element={<Opportunity />} />
			<Route
				path="memberbenefits"
				element={<MemberBenefits />}
			/>
			<Route path="events" element={<Events />} />
			<Route
				path="successstories"
				element={<SuccessStories />}
			/>
			<Route path="igeniuscares" element={<IGeniusCares />} />
			<Route path="investview" element={<Investview />} />
			<Route path="leadership" element={<Leadership />} />
			<Route path="policies" element={<Policies />}>
				<Route
					path="purchaseterms"
					element={<PurchaseTerms />}
				/>
				<Route
					path="privacypolicy"
					element={<PrivacyPolicy />}
				/>
				<Route
					path="distributoragreement"
					element={<DistributorAgreement />}
				/>
				<Route
					path="compliance"
					element={<Compliance />}
				/>
			</Route>
			<Route path="contactus" element={<ContactUs />} />
			<Route path="login" element={<Login />} />
		</Route>
	)
);

function Layout() {

	const cookies = new Cookies();
	var cookiebool = false;
	if(cookies.get('preference')){
		cookiebool = true;
	}
	const [isSubModalOpen, setIsSubModalOpen] = useState(false);
	const [isCookieSet, setCookiePopUp] = useState(cookiebool);
	const [isCookieAccepted, setAcceptedCookie] = useState(cookies.get('preference'));

	useEffect(() => {
		var modal = document.getElementById("modalSub");

		if (isSubModalOpen) {
			modal.style.display = "block";
		} else {
			modal.style.display = "none";
		}
	}, [isSubModalOpen]);

	
	function setCookieAccept(){
		const useridrand = "Accept"
		const expiresDate = new Date();
		expiresDate.setDate(expiresDate.getDate() + 720);
		cookies.set('preference', useridrand, {expires: expiresDate});
		console.log('new cookie set');	
		console.log(cookies.get('preference'));
		setCookiePopUp(true);
		setAcceptedCookie(cookies.get('preference'));

	}


	function setCookieDecline(){
		const useridrand = "Decline";
		const expiresDate = new Date();
		expiresDate.setDate(expiresDate.getDate() + 720);
		cookies.set('preference', useridrand, {expires: expiresDate});
		console.log('new cookie set');	
		console.log(cookies.get('preference'));
		setCookiePopUp(true);
		setAcceptedCookie(cookies.get('preference'));
	}

	if(isCookieAccepted === 'Accept'){
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','G-TK3X66M2PR');
		console.log('analytics set');
	} else if(isCookieAccepted === 'Decline'){
		// do nothing
		console.log('analytics declined');

	} else if(!cookies.get('preference')) {
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','G-TK3X66M2PR');
		console.log('analytics set');
	}
	

	return (
		<div className="App">
			<ScrollRestoration />
			<Header setIsSubModalOpen={setIsSubModalOpen} />
			<Outlet
				context={{
					setIsSubModalOpen,
				}}
			/>
			<Footer />
			{isCookieSet ? '' : 
			<div id="acceptcookies"
				className="cookieModal">
					<div className="cookiesBoxes">
						<div className="cookiesp">This site uses cookies to improve your experience. If you continue to use this site, or if you click ''accept'', you consent to the use of cookies.</div>
						<div className="cookieButtonBox">
							<button className="cookiesButton" onClick={setCookieAccept}>Accept</button>
							<button className="cookiesButton" onClick={setCookieDecline}>Decline</button>
						</div>
					</div>
				
			</div>}
			<div
				id="modalSub"
				style={{ display: "none" }}
				className="modal-container">
				<SubmissionModal
					setIsSubModalOpen={setIsSubModalOpen}
				/>
			</div>
		</div>
	);
}

function App() {
	

	useEffect(() => {
		AOS.init({
			once: true,
			duration: 800,
			offset: 200,
		});
	}, []);


	let domain = /:\/\/([^/]+)/.exec(window.location.href)[1];
	if (domain.match(/igenius\.biz/)) {
		let dp = domain.split(/\./);
		window.replicated = true;
		if (dp.length === 3) {
			window.sponsor = dp[0];
		}
	}
	window.replicated = window.replicated || false;
	window.sponsor = window.sponsor || null;

	return <RouterProvider router={router} />;
}

export default App;
