import React from "react";
import styled from "styled-components";
import newBanner from "../../images/oppBannerNew.png";
import mobileBanner from "../../images/mobileMemberBcompressed.png";
import bottomImageBanner from "../../images/opportunity-bg.png";
import { breakpoints } from "../../utils/breakpoints";
import { useOutletContext } from "react-router-dom";

// sections
import TopBanner from "../../Sections/TopBanner/TopBanner";
import BottomBanner from "../../Sections/BottomBanner/BottomBanner";
import BenefitsSection from "./BenefitsSection";
import FastStartBonusSection from "./FastStartBonusSection";
import UpgradesSection from "./UpgradesSection";
import RapidFireBonusSection from "./RapidFireBonusSection";

const StyledDiv = styled.div`
	overflow-x: hidden;
	background: #ffffff 0% 0% no-repeat padding-box;

	button {
		margin-top: 20px;
	}

	.content-container {
		padding: 100px 0;

		p {
			padding: 50px 0;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.content-container {
			padding: 30px 0;
			p {
				padding: 25px 0;
			}

			.heading {
				font-size: 8vw;
			}

			.content-container {
				width: 100%;
			}

			button {
				margin-top: 10px;
			}
		}
	}
`;

const Opportunity = () => {
	const { setIsSubModalOpen } = useOutletContext();

	return (
		<StyledDiv>
			<TopBanner
				bg={
					window.innerWidth < 750
						? mobileBanner
						: newBanner
				}
				txt={
					<>
						<b>We invite</b> you to join us
						on our mission to help people
						all over the world live more
						brilliant lives.
					</>
				}
				btn={
					<>
						<a
							href
							onClick={() => {
								setIsSubModalOpen(
									true
								);
							}}>
							<button className="section-btn">
								Get Started
							</button>
						</a>
					</>
				}
			/>
			<div className="content-container">
				<BenefitsSection />
				<RapidFireBonusSection />
				<FastStartBonusSection />
				<UpgradesSection />
			</div>
			<div data-aos="fade-down">
				<BottomBanner
					bg={
						window.innerWidth
							? bottomImageBanner
							: bottomImageBanner
					}
					txt={
						"To qualify for each rank, it takes time and hard work. Your participation and achievement is based on the products you sell, and your ability to train others. It should not beconsidered a method to get rich quick. Participation in the Bonus Plan is completely optional. Please refer to the full Bonus Plan and policies for complete program details."
					}
				/>
			</div>
		</StyledDiv>
	);
};

export default Opportunity;
