import React from "react";
import styled from "styled-components";
import { FaCheck, FaTimes } from "react-icons/fa";
import underOrange from "../images/tableunderline.svg";
import { breakpoints } from "../utils/breakpoints";

const StyledDiv = styled.div`

    .tiersdiv{
        text-align: center;
        margin-bottom: 50px;
        text-align: center;
    }

    .xph1{
        color: #3a1f9b; margin: 0px;
    }

    table{
        .plat {
            color: #ff8b2c !important;
       }

    }

    .notes{
        margin: auto;
    
    p{
        text-align: left;
        font-size: 15px;
    }
  

`;

const XploreChart = () => {


    return(
        <StyledDiv>
            <div className="tiersdiv">
                <h1 className='xph1'>XPLOREpro Membership Tiers</h1>
                <img src={underOrange} />
            </div>
            <table>
                        <tbody>
                            <tr className="header">
                                <th className="col-1">
                                    XPLOREpro Benefits
                                </th>
                                <th>Gold</th>
                                <th className="plat">
                                    Platinum
                                </th>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    One Time Membership Price
                                </td>
                                <td>$749.99</td>
                              
                                <td className="plat">
                                    $999.99
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    One Time Membership Price for Elite iGenius Members
                                </td>
                               
                                <td>$649.99</td>
                                <td className="plat">
                                    $899.99
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    One Time Fast Start / One Time PV
                                </td>
                                
                                <td>
                                    $75 / 200
                                </td>
                                <td className="plat">
                                    $100 / 300
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Annual Renewal Price
                                </td>
                               
                                <td>
                                    $574.99 
                                </td>
                                <td className="plat">
                                    $749.99
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Annual Renewal Price for Elite iGenius Members
                                </td>
                                
                                <td>
                                    $499.99
                                </td>
                                <td className="plat">
                                    $649.99
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Annual Fast Start / Annual PV
                                </td>
                               
                                <td>
                                    $60 / 150
                                </td>
                                <td className="plat">
                                    $75 / 200
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Hotel Discounts
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Cruise Discounts
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Rental Car Discounts
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Condo Discounts
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Worldwide Tour Discounts
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Reward Credits for Personal Bookings
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Rewards Credits for Guest Pass Bookings
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Rewards Credits for Guest Pass Bookings
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Annual Vacation Voucher (for 2 travelers)<sup class="footnote">1</sup>
                                </td>
                               
                                <td>
                                    <FaCheck />
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Lifestyle Benefits
                                </td>
                               
                                <td>
                                    
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Premium Cruise Certificate (for 2 travelers)
                                </td>
                               
                                <td>
                                    
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Savings Bonuses on Guest Pass Bookings<sup class="footnote">2</sup>
                                </td>
                               
                                <td>
                                    
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Reward Credits Can Be Redeemed For Lifestyle Benefits<sup class="footnote">3</sup>
                                </td>
                               
                                <td>
                                    
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Savings Bonuses on Personally Enrolled XPLOREpro Member Bookings
                                </td>
                               
                                <td>
                                    
                                </td>
                                <td className="plat">
                                    <FaCheck />
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    XPLOREpro Guest Passes<sup class="footnote">4</sup>
                                </td>
                               
                                <td>
                                    Limited
                                </td>
                                <td className="plat">
                                    Unlimited
                                </td>
                            </tr>
                            <tr>
                                <td className="col-1">
                                    Reward Credits Redemption Window
                                </td>
                               
                                <td>
                                    2 Years
                                </td>
                                <td className="plat">
                                    10 Years
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <br />
                    <div class="notes">
                        <p>1-XPLOREpro vacation voucher must be redeemed within 18 months of issuance.</p>
                        <p>2-Savings bonuses are based on savings, not booking totals and are not paid until after the travel/activity is completed.</p>
                        <p>3-Lifestyle benefits include things like: Gift cards, shopping rewards, lounges, and transportation.</p>
                        <p>4-Platinum guest passes are good for 1 year. Gold members get 10 guest passes per month that are good for 90 days.</p>
                    </div>

        </StyledDiv>
    )
}

export default XploreChart;