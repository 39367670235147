import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";
import eduImg from "../../webp/newIgeniusHomeOverview.webp";
import { motion } from "framer-motion";

const StyledDiv = styled.div`
	overflow-x: hidden;
	padding: 50px 0;
	background: #ffffff 0% 0% no-repeat padding-box;

	.content-container {
		display: grid;
		grid-template-columns: 50% 50%;
		align-items: center;

		.left-side {
			.edu-vid {
				border-radius: 4px 4px 0px 0px;

				img {
					width: 100%;
					cursor: pointer;
				}
			}
		}

		.right-side {
			text-align: left;
			padding-left: 75px;

			.divider {
				margin-left: 0;
			}

			p {
				width: 100%;
				margin: 0;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		padding: 50px 10px;

		.content-container {
			grid-template-columns: 1fr;
			row-gap: 45px;

			.right-side {
				padding: 0 0 20px 0;
				text-align: center;
				grid-row: 1;

				.divider {
					margin: 25px auto;
				}
			}
		}
	}
`;

const EducationSection = ({ isModalOpen, setIsModalOpen, setVidCode }) => {
	return (
		<StyledDiv id="call-to-action">
			<div className="content-container">
				<motion.div
					initial={{ opacity: 0, x: -200 }}
					whileInView={{
						opacity: 1,
						x: 0,
					}}
					viewport={{ once: true }}
					transition={{
						type: "tween",
						duration: .8,
					}}>
					<div className="left-side">
						<div className="edu-vid">
							<img
								onClick={() => {
									setIsModalOpen(
										!isModalOpen
									);
									setVidCode(
										"aQ6xXHn-Zn8"
									);
								}}
								src={eduImg}
								alt=""
							/>
						</div>
					</div>
				</motion.div>
				<motion.div
					initial={{ opacity: 0, x: 200 }}
					whileInView={{
						opacity: 1,
						x: 0,
					}}
					viewport={{ once: true }}
					transition={{
						type: "tween",
						duration: .8,
					}}>
					<div className="right-side">
						<div className="heading">
							Education &amp; Tools
						</div>
						<div className="divider"></div>
						<p>
							<b>
							iGenius offers a variety of educational resources and tools
							</b>{" "}
							for the financial markets. Our market educators provide research, analysis, and host live education sessions.
						</p>
					</div>
				</motion.div>
			</div>
		</StyledDiv>
	);
};

export default EducationSection;
