import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	position: relative;
	background-color: #001313;
	background-size: cover;
	background-position: center;
	min-height: 60vh;

	.banner-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;

		.banner-txt {
			font: normal normal normal 2vw/4vw Nexa-light;
			color: #ffffff;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		min-height: 30vh;

		.banner-content {
			.banner-txt {
				font-size: 4vw;
				padding: 25px 0;
			}
		}
	}
`;

const BottomBanner = ({ bg, txt, btn }) => {
	return (
		<StyledDiv
			style={{
				backgroundImage: `linear-gradient(rgba(0, 19, 19, 0.6), rgba(0, 19, 19, 0.6)), url(${bg})`,
			}}>
			<div className="banner-content">
				<div className="banner-txt">{txt}</div>
				{btn}
			</div>
		</StyledDiv>
	);
};

export default BottomBanner;
