import React from "react";
import styled from "styled-components";
import rapidFire from "../../images/rapid-fire-bonuses-icon.svg";
import chartDesktop from "../../images/NewRapidFireAdjustment.png";
import chartMobile from "../../images/NewRapidFireAdjustmentMobile.png";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	.heading img {
		padding-right: 20px;
		width: 2%;
	}
	.rapid-fire-chart {
		width: 50%;
		margin: auto;
		margin-bottom: 90px;

		img {
			width: 100%;
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		.heading img {
			width: 5%;
		}

		.rapid-fire-chart {
			width: 80%;
			margin-bottom: 20px;
		}
	}
`;

const RapidFireBonusSection = () => {
	return (
		<StyledDiv className="rapid-fire-bonus-section">
			<div data-aos="fade-down">
				<div className="heading">
					<img src={rapidFire} alt="" />
					Rapid Fire Bonus
				</div>
				<p>
					The Rapid Fire Bonus is a residual bonus
					that pays $25 every time your personally
					enrolled members renew their monthly
					subscription.
				</p>
			</div>
			<div className="rapid-fire-chart">
				<img
					src={
						window.screen.width >= 500
							? chartDesktop
							: chartMobile
					}
					alt=""
				/>
			</div>
		</StyledDiv>
	);
};

export default RapidFireBonusSection;
