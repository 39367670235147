import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	position: relative;
	width: 15vw;
	height: 15vw;
	margin: auto;
	font: normal normal normal 1.25vw/2vw Nexa-light;
	display: flex;
	flex-direction: column;
	justify-content: center;
	grid-column: span 2;

	:first-child{
		grid-column: 2/span 2;
	}

	#NoMobileOpp:hover {
		background: transparent
			linear-gradient(145deg, #44b2dd 0%, #84c2e1 100%) 0% 0%
			no-repeat padding-box;
		border-radius: 50%;
		color: #ffffff;
		transform: scale(1.20);
		transition: transform 300ms;
		z-index: 1000;

		.benefit-container {
			justify-content: center;
			height: 15vw;
		}
	}

	.benefit-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 7.5vw;

		img {
			height: 75px;
			width: 75px;
		}
	}

	@media screen and ${breakpoints.device.tablet} {
		font-size: 2vw;
		line-height: 1;
	}

	@media screen and ${breakpoints.device.mobileL} {
		width: 30vw;
		height: 30vw;
		font-size: 4vw;
		grid-column: auto;

		:first-child{
			grid-column: auto;
		}

		:last-child{
			grid-column: span 2;
		}

		.benefit-container .benefit-icon {
			width: 20vw;
			margin: auto;

			img {
				height: 75px;
				width: 75px;
			}
		}
	}
`;

const Benefit = ({ icon, txt }) => {
	return (
		<StyledDiv>
			<div id={window.innerWidth > 650 ? "NoMobileOpp" : ""}>
				<div className="benefit-container" >
				<div className="benefit-icon">{icon}</div>
				<div className="benefit-txt">{txt}</div>
				</div>
			</div>
		</StyledDiv>
	);
};

export default Benefit;
