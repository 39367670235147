import React from "react";
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa";
import { breakpoints } from "../../utils/breakpoints";

const StyledDiv = styled.div`
	font: normal normal normal 1.5vw/3vw Nexa-light;
	padding-top: 25px;

	table {
		width: 90%;
		margin: auto;
		border-collapse: collapse;
		margin-top: 50px;

		.col-1 {
			text-align: left;
			width: 55%;
		}

		th,
		td {
			padding: 10px 25px;
		}

		tr {
			border-bottom: 1px solid #ddd;

			&:nth-child(even) {
				background-color: #f9f9f9;
			}

			&:hover {
				background-color: #ebf2f7;
			}
		}
	}

	@media screen and ${breakpoints.device.mobileL} {
		font-size: 3.1vw;

		table {
			width: 98%;

			th,
			td {
				padding: 8px 6px;
			}
		}
	}
`;

const UpgradesSection = () => {
	return (
		<StyledDiv className="upgrades-section">
			<div data-aos="fade-down">
				<div className="heading">Upgrades</div>
			</div>
			<table>
				<tbody>
					<tr>
						<td className="col-1"></td>
						<th>One-Time Fee</th>
						<th>Bonus</th>
						<th>PV</th>
					</tr>
					<tr>
						<th className="col-1">
							Choice
						</th>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td className="col-1">
							<FaArrowRight /> Select
						</td>
						<td>$200</td>
						<td>$15</td>
						<td>75</td>
					</tr>
					<tr>
						<td className="col-1">
							<FaArrowRight /> Premium
						</td>
						<td>$500</td>
						<td>$40</td>
						<td>200</td>
					</tr>
					<tr>
						<td className="col-1">
							<FaArrowRight /> Elite
						</td>
						<td>$1400</td>
						<td>$100</td>
						<td>900</td>
					</tr>
					<tr>
						<th className="col-1">
							Select
						</th>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td className="col-1">
							<FaArrowRight /> Premium
						</td>
						<td>$300</td>
						<td>$25</td>
						<td>125</td>
					</tr>
					<tr>
						<td className="col-1">
							<FaArrowRight /> Elite
						</td>
						<td>$1200</td>
						<td>$50</td>
						<td>650</td>
					</tr>
					<tr>
						<th className="col-1">
							Premium
						</th>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td className="col-1">
							<FaArrowRight /> Elite
						</td>
						<td>$900</td>
						<td>$75</td>
						<td>800</td>
					</tr>
				</tbody>
			</table>
		</StyledDiv>
	);
};

export default UpgradesSection;
